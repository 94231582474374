import React from 'react';
import Curriculum from '../components/Curriculum/Curriculum';

function CV() {

    return (
        <>
            <Curriculum />

        </>
    );
}

export default CV;