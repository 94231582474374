import React from 'react';
import ContactMe from '../components/ContactForm/ContactForm';
import Contact from '../components/Contact/Contact';


function ContactPage() {
  

    return (
        <>
            <Contact />
            <br /> <br /> <br />
            <ContactMe />
        </>
    );
}

export default ContactPage;